.main {
  padding-top: 60px;
  width: 500px;
  margin: auto;
  color: #353740;
}

.main section {
  width: 100%;
}

.main h1 {
  font-size: 32px;
  line-height: 40px;
  align-self: flex-start;
}