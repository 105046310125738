.container {
  width: 100%;
}
.container form {
  display: flex;
  flex-direction: column;
}

.container label {
  font-weight: bold;
  padding-bottom: 2px;
}

.container textarea {
  font-size: 16px;
  padding: 0.25em 0.5em;
  border: 1px solid rgb(151, 149, 149);
  border-radius: 4px;
  height: 10em;
}
.container textarea:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE;
}

.container form select {
  margin-top: 4px;
  padding: 8px 4px;
  border-radius: 4px;
  font-size: 14px;
}

.container form select:focus {
  outline: none !important;
  box-shadow: 0 0 10px #719ECE;
}

.container button {
  margin-top: 4px;
  padding: 12px 24px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  text-align: center;
  align-self: flex-end;
  cursor: pointer;
}

.error {
  font-size: 12px;
  color: #cc1f1f;
  margin: 2px;
}