.container {
  width: 100%;
}
.container .card {
  background-color: rgb(236, 234, 234);
  border: none;
  border-radius: 4px;
  padding: 0.25em 0.75em;
  margin-top: 10px;
}

.container .card div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container .card div h4 {
  width: 15%;
  float: left;
  margin: 10px auto 0 0;
}

.container .card div p {
  width: 75%;
  margin: 10px auto 10px 0;
  font-weight: 600;
}

.results {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 10;
}

.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
  padding: 5px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: pink;
  border-radius: 4px;
}

.error p {
  font-weight: 600;
  color: red;
}

.close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 10px;
  color: gray;
}
.close:hover {
  opacity: 1;
}